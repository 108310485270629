import { createGlobalStyle, css } from "styled-components";
import { CSS_COLORS } from "./Colors";

const cssCommon = css`
  * {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar {
    width: 6px;
  }

  *::-webkit-scrollbar-thumb {
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    width: 6px;
  }

  html,
  body {
    height: 100%;
    font-family: "Pretendard";
    font-weight: 400;
    font-size: 16px;
    min-width: 320px;
    background-color: var(--surface);
    user-select: none;
  }

  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .grid-item-small {
    .grid-item-left {
      width: 100%;
    }

    .widget-handle-bar,
    .grid-item-right {
      display: none;
    }
  }

  #time-interval {
    opacity: 0;
    position: absolute;
    top: 48px;
    right: 20px;
    display: flex;
    max-width: 68px;
    max-height: 28px;
    flex-direction: row-reverse;

    .select-box {
      border-radius: 14px;
      width: 68px;
      min-height: 28px;
      padding: 6px 8px 6px 12px;
      font-size: 13px;
      font-weight: 400;

      .select-icon-remove,
      .select-icon-arrow {
        svg path {
          fill: var(--icon-emphasis);
        }
      }

      > div:first-child {
        display: block;
        white-space: nowrap;
        overflow: unset;
        text-overflow: unset;
        line-height: normal;
      }
    }

    .option-list {
      border-radius: 4px;
      top: 28px;
      box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
      width: 100px;
    }
  }

  .widget-body:has(.grid-item-right:hover),
  .widget-body:has(#time-interval:hover) {
    #time-interval {
      opacity: 1;
    }
  }
`;

const cssReset = css`
  * {
    padding: 0;
    margin: 0;

    :disabled {
      cursor: not-allowed;
    }
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-style: inherit;
    font-weight: inherit;
  }

  b {
    font-weight: 700;
  }

  div {
    box-sizing: border-box;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  html {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    text-size-adjust: none;
  }

  body {
    line-height: 1;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  ul,
  ol,
  li {
    list-style: none;
  }

  ul::after {
    content: "";
    display: block;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  legend {
    position: absolute;
    top: -9999px;
    left: -9999px;
    font-size: 0;
    line-height: 0;
  }

  input,
  select,
  textarea,
  button {
    vertical-align: middle;
    font-family: inherit;
    font-size: inherit;
    border-radius: 3px;
  }

  textarea {
    resize: none;
  }

  select,
  input {
    cursor: auto;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  input[type="text"],
  input[type="password"],
  input[type="tel"],
  input[type="email"] {
    box-sizing: border-box;
  }

  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }

  iframe {
    transition: height ease 0.5s;
  }
`;

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => CSS_COLORS[theme.type]}
  ${cssReset}
  ${cssCommon}
`;

export const StandardScrollBarStyle = css`
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--background-scroll-thumb);
    border: none;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 8px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
`;

export const DashboardTreeNodeCommon = css<{ isNoGroup?: boolean }>`
  .sm-tree-treenode {
    position: relative;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    border-radius: 4px;
    height: 28px;
    color: var(--text-secondary-1);

    path {
      fill: var(--icon-default);
      stroke: var(--icon-default);
    }

    rect {
      stroke: var(--icon-default);
    }

    :hover {
      background-color: var(--report-email-user-list-hover);
      border-radius: 4px;
      color: var(--text-primary);

      svg {
        path {
          fill: var(--icon-emphasis);
          stroke: var(--icon-emphasis);
        }

        rect {
          stroke: var(--icon-emphasis);
        }
      }
    }
  }

  .sm-tree-treenode-selected {
    background-color: var(--background-header-lnb-hover);
    color: var(--text-primary);
    border-radius: 4px;

    svg {
      path {
        fill: var(--icon-emphasis);
        stroke: var(--icon-emphasis);
      }

      rect {
        stroke: var(--icon-emphasis);
      }
    }
  }

  .sm-tree-node-content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 2px;
  }

  .tree-node-dashboard {
    padding-left: 50px;
    .title-text {
      width: 160px;
    }
  }
`;
